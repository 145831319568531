<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path
        class="st0"
        d="M246.487,79.911c-6.242-9.952-19.372-12.96-29.322-6.717c-9.955,6.241-12.961,19.371-6.719,29.323
		c6.244,9.952,19.372,12.958,29.324,6.717C249.722,102.992,252.728,89.863,246.487,79.911z M235.537,80.927l-4.077,18.001
		c-0.132,0.583-0.491,1.089-0.996,1.406c-0.004,0.002-0.006,0.004-0.009,0.005c-0.511,0.316-1.127,0.417-1.711,0.276l-9.327-2.245
		c-1.207-0.291-1.949-1.503-1.658-2.709c0.289-1.206,1.502-1.949,2.709-1.659l7.113,1.713l3.575-15.781
		c0.273-1.21,1.476-1.969,2.687-1.695C235.053,78.514,235.812,79.717,235.537,80.927z"
      />
      <path
        class="st0"
        d="M222.759,122.248l-65.845,41.307c-7.696-8.035-18.503-13.07-30.483-13.07c-3.479,0-6.846,0.47-10.084,1.269
		C106.11,132.041,66.71,56.583,49.778,29.832c-9.05-14.278-22.374-18.559-35.66-11.47C-0.907,26.388-0.854,44.154,1.273,52.429
		c0.867,3.378,4.335,5.409,7.687,4.554c3.386-0.854,5.442-4.298,4.604-7.684c-0.145-0.59-3.432-14.427,6.532-19.748
		c2.674-1.437,10.821-5.792,18.963,7.068c16.182,25.559,54.68,99.161,65.532,120.02c-12.208,7.415-20.41,20.801-20.41,36.102
		c0,23.3,18.956,42.255,42.25,42.255c23.3,0,42.256-18.956,42.256-42.255c0-6.758-1.635-13.127-4.467-18.798l65.277-40.95
		c2.968-1.863,3.864-5.777,2.002-8.741C229.633,121.283,225.715,120.391,222.759,122.248z M126.43,224.341
		c-17.422,0-31.596-14.177-31.596-31.6s14.173-31.6,31.596-31.6c17.425,0,31.601,14.177,31.601,31.6S143.856,224.341,126.43,224.341
		z"
      />
      <path
        class="st0"
        d="M151.046,151.607c1.022,1.627,3.17,2.119,4.797,1.097l60.155-37.728c-4.693-1.995-8.849-5.383-11.764-10.03
		c-7.583-12.092-3.93-28.041,8.162-35.626l-17.85-28.46l-9.581-15.274c-1.02-1.626-3.167-2.121-4.797-1.099l-27.573,17.295
		l16.394,26.139l-14.255-0.486l-5.769,13.046l-16.396-26.137l-27.572,17.294c-1.628,1.021-2.12,3.17-1.1,4.797L151.046,151.607z"
      />
      <circle class="st0" cx="126.475" cy="192.741" r="13.967" />
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconTrolley',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

<!-- <g>
      <path
        class="st0"
        d="M246.487,79.911c-6.242-9.952-19.372-12.96-29.322-6.717c-9.955,6.241-12.961,19.371-6.719,29.323
		c6.244,9.952,19.372,12.958,29.324,6.717C249.722,102.992,252.728,89.863,246.487,79.911z M235.537,80.927l-4.077,18.001
		c-0.132,0.583-0.491,1.089-0.996,1.406c-0.004,0.002-0.006,0.004-0.009,0.005c-0.511,0.316-1.127,0.417-1.711,0.276l-9.327-2.245
		c-1.207-0.291-1.949-1.503-1.658-2.709c0.289-1.206,1.502-1.949,2.709-1.659l7.113,1.713l3.575-15.781
		c0.273-1.21,1.476-1.969,2.687-1.695C235.053,78.514,235.812,79.717,235.537,80.927z"
      />
      <path
        class="st0"
        d="M222.759,122.248l-65.845,41.307c-7.696-8.035-18.503-13.07-30.483-13.07c-3.479,0-6.846,0.47-10.084,1.269
		C106.11,132.041,66.71,56.583,49.778,29.832c-9.05-14.278-22.374-18.559-35.66-11.47C-0.907,26.388-0.854,44.154,1.273,52.429
		c0.867,3.378,4.335,5.409,7.687,4.554c3.386-0.854,5.442-4.298,4.604-7.684c-0.145-0.59-3.432-14.427,6.532-19.748
		c2.674-1.437,10.821-5.792,18.963,7.068c16.182,25.559,54.68,99.161,65.532,120.02c-12.208,7.415-20.41,20.801-20.41,36.102
		c0,23.3,18.956,42.255,42.25,42.255c23.3,0,42.256-18.956,42.256-42.255c0-6.758-1.635-13.127-4.467-18.798l65.277-40.95
		c2.968-1.863,3.864-5.777,2.002-8.741C229.633,121.283,225.715,120.391,222.759,122.248z M126.43,224.341
		c-17.422,0-31.596-14.177-31.596-31.6s14.173-31.6,31.596-31.6c17.425,0,31.601,14.177,31.601,31.6S143.856,224.341,126.43,224.341
		z"
      />
      <path
        class="st0"
        d="M151.046,151.607c1.022,1.627,3.17,2.119,4.797,1.097l60.155-37.728c-4.693-1.995-8.849-5.383-11.764-10.03
		c-7.583-12.092-3.93-28.041,8.162-35.626l-17.85-28.46l-9.581-15.274c-1.02-1.626-3.167-2.121-4.797-1.099l-27.573,17.295
		l16.394,26.139l-14.255-0.486l-5.769,13.046l-16.396-26.137l-27.572,17.294c-1.628,1.021-2.12,3.17-1.1,4.797L151.046,151.607z"
      />
      <circle class="st0" cx="126.475" cy="192.741" r="13.967" />
    </g> -->
